import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export function fetchAPIAudit<
  T = unknown,
  R extends NitroFetchRequest = NitroFetchRequest,
  O extends NitroFetchOptions<R> = NitroFetchOptions<R>,
>(request: R, options?: O) {
  const { $apiAudit } = useNuxtApp()
  return $apiAudit<T, R, O>(request, options)
}

<script setup lang="ts">
import type { FormKitNode } from '@formkit/core'
import type { ITenantSchedule } from '@audit'

const dayjs = useDayjs()

const props = defineProps<{
  mode: 'create' | 'update'
  schedule?: ITenantSchedule
}>()

const emit = defineEmits<{
  close: []
  success: []
}>()
const { currentDivision } = useDivisions()

const addTenancySchedule = useAPIAddTenancySchedule(currentDivision.value.id)
const updateTenancySchedule = useAPIUpdateTenancySchedule(
  currentDivision.value.id,
)

const scheduleValue = computed(() => props.schedule)
const initialValue = (): Partial<ITenantSchedule> => ({
  tenantName: scheduleValue.value?.tenantName || '',
  premises: scheduleValue.value?.premises || '',
  lettableArea: scheduleValue.value?.lettableArea,
  leaseTerm: scheduleValue.value?.leaseTerm,
  commencementDate: scheduleValue.value?.commencementDate,
  expiryDate: scheduleValue.value?.expiryDate,
  reviewDate: scheduleValue.value?.reviewDate,
  reviewType: scheduleValue.value?.reviewType || '',
  remainingTerm: scheduleValue.value?.remainingTerm,
  baseRentPerAnnum: scheduleValue.value?.baseRentPerAnnum,
  baseRentPerSquareMeter: scheduleValue.value?.baseRentPerSquareMeter,
  outgoingsPerAnnum: scheduleValue.value?.outgoingsPerAnnum,
  outgoingsPerSquareMeter: scheduleValue.value?.outgoingsPerSquareMeter,
  marketingLevy: scheduleValue.value?.marketingLevy,
  totalRentPerAnnum: scheduleValue.value?.totalRentPerAnnum,
  totalRentPerSquareMeter: scheduleValue.value?.totalRentPerSquareMeter,
})

const formValues = ref<ITenantSchedule>(initialValue() as ITenantSchedule)

const form = ref<{ node: FormKitNode }>()

const updateDates = (changingField: string) => {
  const commencementDate = formValues.value.commencementDate
    ? dayjs(new Date(formValues.value.commencementDate))
    : null
  const expiryDate = formValues.value.expiryDate
    ? dayjs(new Date(formValues.value.expiryDate))
    : null
  const leaseTerm = formValues.value.leaseTerm
    ? parseFloat(formValues.value.leaseTerm as string)
    : null

  console.log(commencementDate, expiryDate, leaseTerm)
  if (commencementDate && expiryDate && leaseTerm) {
    // All fields are filled
    if (
      changingField === 'commencementDate' ||
      changingField === 'expiryDate'
    ) {
      const monthsDifference = expiryDate.diff(commencementDate, 'month')
      formValues.value.leaseTerm = (monthsDifference / 12).toFixed(2)
    } else if (changingField === 'leaseTerm') {
      const totalMonths = Math.round(leaseTerm * 12)
      formValues.value.expiryDate = commencementDate
        .add(totalMonths, 'month')
        .format('DD/MM/YYYY')
    }
  } else if (commencementDate && expiryDate) {
    // Only commencement and expiry dates are filled
    const monthsDifference = expiryDate.diff(commencementDate, 'month')
    formValues.value.leaseTerm = (monthsDifference / 12).toFixed(2)
  } else if (commencementDate && leaseTerm) {
    // Only commencement date and lease term are filled
    const totalMonths = Math.round(leaseTerm * 12)
    formValues.value.expiryDate = commencementDate
      .add(totalMonths, 'month')
      .format('DD/MM/YYYY')
  } else if (expiryDate && leaseTerm) {
    // Only expiry date and lease term are filled
    const totalMonths = Math.round(leaseTerm * 12)
    formValues.value.commencementDate = expiryDate
      .subtract(totalMonths, 'month')
      .format('DD/MM/YYYY')
  }
}

watch(
  [() => formValues.value.commencementDate, () => formValues.value.expiryDate],
  () => {
    const currentDate = dayjs()

    if (formValues.value.expiryDate) {
      const expiryDate = dayjs(formValues.value.expiryDate)

      // Calculate remaining term
      const daysRemaining = expiryDate.diff(currentDate, 'day')
      const yearsRemaining = daysRemaining / 365.25 // Consider leap years

      formValues.value.remainingTerm =
        daysRemaining > 0 ? yearsRemaining.toFixed(1) : '0.0'
    } else {
      formValues.value.remainingTerm = undefined
    }

    // Calculate lease term
    if (formValues.value.commencementDate && formValues.value.expiryDate) {
      const commencementDate = dayjs(formValues.value.commencementDate)
      const expiryDate = dayjs(formValues.value.expiryDate)

      const daysLease = expiryDate.diff(commencementDate, 'day')
      const yearsLease = daysLease / 365.25 // Consider leap years

      formValues.value.leaseTerm = yearsLease.toFixed(2)
    } else {
      formValues.value.leaseTerm = undefined
    }
  },
  { immediate: true },
)

const submitHandler = useSubmitHandler<ITenantSchedule>(
  (data) =>
    props.mode === 'create'
      ? addTenancySchedule.mutateAsync({ payload: data })
      : updateTenancySchedule.mutateAsync({
          payload: { ...data, id: scheduleValue.value!.id },
        }),
  {
    loadingMessage:
      props.mode === 'create'
        ? 'Creating tenancy schedule...'
        : 'Updating tenancy schedule...',
    successMessage:
      props.mode === 'create'
        ? 'Tenancy schedule created successfully'
        : 'Tenancy schedule updated successfully',
    errorMessage:
      props.mode === 'create'
        ? 'Failed to create tenancy schedule'
        : 'Failed to update tenancy schedule',
    onSuccess: () => {
      emit('success')
      emit('close')
    },
  },
)
</script>

<template>
  <ModalBase
    :title="
      mode === 'create'
        ? 'Create a new tenancy schedule'
        : 'Update tenancy schedule'
    "
    :primary-button-text="mode === 'create' ? 'Create' : 'Update'"
    modal-class="max-w-[800px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
    @confirm="form?.node.submit()"
    @close="emit('close')"
  >
    <FormKit
      ref="form"
      v-model="formValues"
      type="form"
      form-class="w-full grid grid-cols-1 md:grid-cols-2 gap-6"
      :actions="false"
      @submit="submitHandler"
    >
      <FormKit
        type="text"
        name="tenantName"
        label="Tenant"
        validation="required"
      />
      <FormKit
        type="text"
        name="premises"
        label="Level/Suite"
        validation="required"
      />
      <FormKit type="number" name="lettableArea" label="Lettable Area m²">
      </FormKit>

      <FormKit type="datepicker" name="commencementDate" label="Comm. Date" />
      <FormKit type="datepicker" name="expiryDate" label="Expiry Date" />
      <FormKit type="datepicker" name="reviewDate" label="Next Rent Review" />
      <FormKit type="text" name="reviewType" label="Next Review Type" />

      <FormKit type="currency" name="baseRentPerAnnum" label="Base Rent $pa" />
      <FormKit
        type="currency"
        name="baseRentPerSquareMeter"
        label="Base Rent $/m²"
      />
      <FormKit type="currency" name="outgoingsPerAnnum" label="Outgoings $pa" />
      <FormKit
        type="currency"
        name="outgoingsPerSquareMeter"
        label="Outgoings $/m²"
      />
      <FormKit
        type="currency"
        name="marketingLevy"
        label="Marketing Levy $pa"
      />
      <FormKit
        type="currency"
        name="totalRentPerAnnum"
        label="Total Rent $pa"
      />
      <FormKit
        type="currency"
        name="totalRentPerSquareMeter"
        label="Total Rent $/m²"
      />

      <FormKit
        type="text"
        name="remainingTerm"
        label="Remaining Term (yrs)"
        :disabled="true"
      />
      <FormKit
        type="text"
        name="leaseTerm"
        label="Lease Term (yrs)"
        :disabled="true"
      />
    </FormKit>
  </ModalBase>
</template>

import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { ITenantSchedule } from '..'

export default (divisionId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ payload }: { payload: ITenantSchedule }) => {
      if (!divisionId) {
        return Promise.resolve(undefined)
      }
      return fetchAPIAudit<ITenantSchedule>(
        `/companies/${divisionId}/extractedSchedule/${payload.id}`,
        {
          method: 'POST',

          body: JSON.stringify(payload),
        },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['divisions', divisionId, 'extracted-schedule'],
      })
    },
  })
}
